import React from 'react';
import { connect } from "react-redux";

function FeaturesList({ classes, currentContent }) {
  return (
    <div className={'sidebar__features ' + (classes ? classes : '')}>
      <h3 className="sidebar__heading u-margin-bottom-small">
        { currentContent.featuresList.heading }
      </h3>
      <ul className="sidebar__list">
        <li className="sidebar__item">
          <span className="sidebar__item-icon">
          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M18 4.5h-4v-2l-2-2H8l-2 2v2H2a2 2 0 0 0-2 2v11c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2v-11a2 2 0 0 0-2-2zm-10-2h4v2H8v-2zM8.5 16L5 12.5l1.4-1.4 2.1 2.1L13.7 8l1.4 1.4L8.5 16z" fill="currentColor" fillRule="evenodd"/></svg>
          </span>
          <p className="sidebar__item-paragraph">
            { currentContent.featuresList.items.item1 }
          </p>
        </li>
        <li className="sidebar__item">
          <span className="sidebar__item-icon">
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M10.5.5C7 .5 3.9 2.4 2.3 5.3L0 3v6.5h6.5L3.7 6.7C5 4.2 7.5 2.5 10.5 2.5c4.1 0 7.5 3.4 7.5 7.5s-3.4 7.5-7.5 7.5c-3.3 0-6-2.1-7.1-5H1.3c1.1 4 4.8 7 9.2 7a9.5 9.5 0 1 0 0-19zM9 5.5v5.1l4.7 2.8.8-1.3-4-2.4V5.5H9z" fill="currentColor" fillRule="evenodd" opacity=".9"/></svg>
          </span>
          <p className="sidebar__item-paragraph">
            { currentContent.featuresList.items.item2 }
          </p>
        </li>
        <li className="sidebar__item">
          <span className="sidebar__item-icon">
            <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 0h-.2L12 2.1 6 0 .4 1.9c-.2.1-.4.2-.4.5v15.1c0 .3.2.5.5.5h.2L6 15.9l6 2.1 5.6-1.9c.2-.1.4-.3.4-.5V.5c0-.3-.2-.5-.5-.5zM12 16l-6-2.1V2l6 2.1V16z" fill="currentColor" fillRule="evenodd"/></svg>
          </span>
          <p className="sidebar__item-paragraph">
            { currentContent.featuresList.items.item3 }
          </p>
        </li>
        <li className="sidebar__item">
          <span className="sidebar__item-icon">
            <svg width="18" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M16 0H2a2 2 0 0 0-2 2v12.9c0 .7.3 1.3.9 1.7L9 22l8.1-5.4c.5-.4.9-1 .9-1.7V2a2 2 0 0 0-2-2zM7 15l-5-5 1.4-1.4L7 12.2l7.6-7.6L16 6l-9 9z" fill="currentColor" fillRule="evenodd"/></svg>
          </span>
          <p className="sidebar__item-paragraph">
            { currentContent.featuresList.items.item4 }
          </p>
        </li>
      </ul>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentContent: state.currentContent,
  }
}

export default connect(mapStateToProps)(FeaturesList);
