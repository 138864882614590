import React from 'react';
import ToolbarSocial from '../../Header/ToolbarSocial/ToolbarSocial';

import tripAdvisor from '../../../assets/tripadvisor.png';

function FooterContact({ content }) {
  return (
    <div className="footer__contact
                    footer__single"
    >
      <h3 className="footer__text-heading
                      u-margin-bottom-small"
      >
        { content.contactInfo }
      </h3>
      <address className="footer__contact-address">
        <span className="footer__text-label">
          { content.address }:
        </span>
        Planos Zakynthos, Greece
      </address>
      <a href="tel:+30 26950 28607" className="footer__contact-link">
        <span className="footer__text-label">
          { content.phone }:
        </span>
        +30 26950 28607
      </a>
      <a href="mailto:info@abbacruises.com" className="footer__contact-link">
        info@abbacruises.com
      </a>
      <div className="u-margin-top-medium">
        <ToolbarSocial socialClass="toolbar__social-link--footer"/>
      </div>
      <a
        href="//pl.tripadvisor.com/Attraction_Review-g775851-d10333020-Reviews-Abba_Travel-Tsilivi_Zakynthos_Ionian_Islands.html?m=19905"
        className="footer__about-link"
      >
        <img src={tripAdvisor}
             alt="Trip Advisor"
             className="footer__about-img"
        />
      </a>
    </div>
  )
}

export default FooterContact;
