import React from 'react';
import { connect } from "react-redux";

function Support({ currentContent }) {
  return (
    <article className="support">
      <h3 className="support__heading">
        {currentContent.support.heading}
      </h3>
      <p className="support__paragraph">
        {currentContent.support.paragraph}
      </p>
      <a href="tel:+30 6977741500"
          className="support__link
                      support__link--phone
                      u-margin-bottom-small"
      >
        <span className="support__link-icon">
          <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 18c-2 2-2 4-4 4s-4-2-6-4-4-4-4-6 2-2 4-4-4-8-6-8-6 6-6 6c0 4 4.1 12.1 8 16 3.9 3.9 12 8 16 8 0 0 6-4 6-6s-6-8-8-6z" fill="currentColor" fillRule="nonzero"/>
          </svg>
        </span>
        +30 6977741500 - ANDREAS
      </a>
      <a href="tel:+30 6976000124"
          className="support__link
                      support__link--phone
                      u-margin-bottom-small"
      >
        <span className="support__link-icon">
          <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 18c-2 2-2 4-4 4s-4-2-6-4-4-4-4-6 2-2 4-4-4-8-6-8-6 6-6 6c0 4 4.1 12.1 8 16 3.9 3.9 12 8 16 8 0 0 6-4 6-6s-6-8-8-6z" fill="currentColor" fillRule="nonzero"/>
          </svg>
        </span>
        +30 6976000124 - PAVLOS
      </a>
      <a href="tel:+30 6976000125"
         className="support__link
                      support__link--phone
                      u-margin-bottom-small"
      >
        <span className="support__link-icon">
          <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 18c-2 2-2 4-4 4s-4-2-6-4-4-4-4-6 2-2 4-4-4-8-6-8-6 6-6 6c0 4 4.1 12.1 8 16 3.9 3.9 12 8 16 8 0 0 6-4 6-6s-6-8-8-6z" fill="currentColor" fillRule="nonzero"/>
          </svg>
        </span>
        +30 6976000125 - ALEX
      </a>
      {/*<a href="tel:+48 514723103"*/}
      {/*   className="support__link*/}
      {/*                support__link--phone*/}
      {/*                u-margin-bottom-small"*/}
      {/*>*/}
      {/*  <span className="support__link-icon">*/}
      {/*    <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">*/}
      {/*      <path d="M22 18c-2 2-2 4-4 4s-4-2-6-4-4-4-4-6 2-2 4-4-4-8-6-8-6 6-6 6c0 4 4.1 12.1 8 16 3.9 3.9 12 8 16 8 0 0 6-4 6-6s-6-8-8-6z" fill="currentColor" fillRule="nonzero"/>*/}
      {/*    </svg>*/}
      {/*  </span>*/}
      {/*  +48 514723103 - DARIA*/}
      {/*</a>*/}
      <a href="tel:+30 2695028607"
         className="support__link
                      support__link--phone
                      u-margin-bottom-small"
      >
        <span className="support__link-icon">
          <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 18c-2 2-2 4-4 4s-4-2-6-4-4-4-4-6 2-2 4-4-4-8-6-8-6 6-6 6c0 4 4.1 12.1 8 16 3.9 3.9 12 8 16 8 0 0 6-4 6-6s-6-8-8-6z" fill="currentColor" fillRule="nonzero"/>
          </svg>
        </span>
        +30 2695028607 - OFFICE
      </a>
      <a href="mailto:info@abbacruises.com"
          className="support__link
                      u-margin-bottom-small"
      >
        <span className="support__link-icon">
          <svg width="30" height="22" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 10.5l15-6.9V0H0v3.58l15 6.92zm0 4.06L0 7.63V22h30V7.65l-15 6.9z" fill="currentColor" fillRule="nonzero"/>
          </svg>
        </span>
        info@abbacruises.com
      </a>
    </article>
  )
}

const mapStateToProps = state => {
  return {
    currentContent: state.currentContent,
  }
}

export default connect(mapStateToProps)(Support);
