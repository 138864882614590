import React from 'react';
import { connect } from "react-redux";
import Hoc from '../../hoc/hoc';
// import MainSlider from '../../components/MainSlider/MainSlider';
import Featurs from '../../components/Features/Features';
import PopularTours from '../../components/PopularTours/PopularTours';
import PopularServices from '../../components/PopularServices/PopularServices';
import CategoryList from '../../components/CategoryList/CategoryList';
import VideoSection from '../../components/VideoSection/VideoSection';
import JoinUs from '../../components/JoinUs/JoinUs';
import BookWithUs from '../../components/BookWithUs/BookWithUs';
import MainSliderSingleVideo from "../../components/MainSliderSingleVideo/MainSliderSingleVideo";


function Home(props) {

  return (
    <Hoc>
      <MainSliderSingleVideo
        header="main-slider__single--shipwreck-tour"
        subHeading={props.currentContent.mainSlider.slide1.subHeading}
        mainHeading={props.currentContent.mainSlider.slide1.mainHeading}
        paragraph={props.currentContent.mainSlider.slide1.paragraph}
        linkLabel={props.currentContent.buttons.primary}
        linkTo="/tours/1"
      />
      {/*<MainSlider />*/}
      <Featurs content={props.currentContent.features}/>
      <PopularTours content={props.currentContent.popularTours}/>
      <PopularServices content={props.currentContent.popularServices}/>
      <CategoryList content={props.currentContent.browseByCategory} categories={props.currentContent.nav}/>
      <JoinUs content={props.currentContent.joinUs}/>
      <VideoSection content={props.currentContent.videoSection}/>
      <BookWithUs content={props.currentContent.bookWithUs}/>
    </Hoc>
  )
}

const mapStateToProps = state => {
  return {
    currentContent: state.currentContent,
  }
}

export default connect(mapStateToProps)(Home);
