import React from 'react';
import { NavLink } from 'react-router-dom';

function NavItem(props) {
  return (
    <li className="nav__item">
      <NavLink
        to={"/" + props.category}
        exact
        className={props.class ? props.class : ''}
        activeClassName={props.classActive ? props.classActive : ''}
        onClick={props.action ? props.action : null}
      >
        {props.children}
      </NavLink>
    </li>
  )
}

export default NavItem;