import React from 'react';

function ListIconItem(props) {
  
  return (
    
    
    <li className={props.itemClass ? props.itemClass : ''}>
      <div className="list-icon-item__miniature">
        {props.children}
      </div>
      <div className="list-icon-item__description">
        <p className="list-icon-item__text-heading">
          {props.title}
        </p>
        <p className="list-icon-item__text-paragraph">
          {props.content}
        </p>
      </div>
    </li>
  )
}

export default ListIconItem;