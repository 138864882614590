import React from 'react';
import Hoc from '../../hoc/hoc';

function SingleOfferOverview(props) {
  return (
    <Hoc>
      <section className="single-offer-overview">
        <div className="container">
          <ul className="single-offer-overview__list">
            <li className="single-offer-overview__item">
              <span className="single-offer-overview__icon">
                <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg"><path d="M13.51 15.37l-4.32-4.33V5.25h2.62v4.7l3.56 3.56-1.86 1.86zM10.5 0a10.5 10.5 0 1 0 0 21 10.5 10.5 0 0 0 0-21zm0 18.38a7.88 7.88 0 1 1 0-15.76 7.88 7.88 0 0 1 0 15.75z" fill="currentColor" fillRule="nonzero"/></svg>
              </span>
              <p className="single-offer-overview__paragraph">
                {props.time} { props.content.time }
              </p>
            </li>

            {
              props.priceChild ?
                <li className="single-offer-overview__item">
                  <span className="single-offer-overview__icon">
                    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg"><path d="M24.606.467a.833.833 0 0 0-1.178 0L21.944 1.95a1.667 1.667 0 0 0-.427-.061h-8.428c-.442 0-.866.176-1.178.489L.844 13.438c-.65.652-.65 1.706 0 2.356l8.428 8.428c.65.65 1.705.65 2.356 0l11.06-11.06c.312-.314.485-.738.484-1.179V3.556a1.667 1.667 0 0 0-.06-.428l1.482-1.484a.833.833 0 0 0 .012-1.177zM21.51 11.983L10.45 23.044l-8.428-8.427L13.09 3.556h7.25L17.794 6.1a2.744 2.744 0 0 0-1.272-.322A2.778 2.778 0 1 0 19.3 8.556a2.744 2.744 0 0 0-.328-1.273l2.545-2.544-.006 7.244zm-3.889-3.427a1.111 1.111 0 1 1-1.11-1.112 1.111 1.111 0 0 1 1.116 1.112h-.006z" fill="currentColor" fillRule="evenodd"/></svg>
                  </span>
                  <p className="single-offer-overview__paragraph">
                    { props.content.childPrice }: €{props.priceChild}
                  </p>
                </li> : null
            }

            <li className="single-offer-overview__item">
              <span className="single-offer-overview__icon">
                <svg width="22" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M15 6c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3zM7 6c1.7 0 3-1.3 3-3S8.7 0 7 0 4 1.3 4 3s1.3 3 3 3zm0 2c-2.3 0-7 1.2-7 3.5V14h14v-2.5C14 9.2 9.3 8 7 8zm8 0c-.3 0-.6 0-1 .1 1.2.8 2 2 2 3.4V14h6v-2.5C22 9.2 17.3 8 15 8z" fill="currentColor" fillRule="evenodd"/></svg>
              </span>
              <p className="single-offer-overview__paragraph">
                { props.content.maxPeople }: {props.maxPeople}
              </p>
            </li>
            <li className="single-offer-overview__item">
              <span className="single-offer-overview__icon">
                <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg"><path d="M24.606.467a.833.833 0 0 0-1.178 0L21.944 1.95a1.667 1.667 0 0 0-.427-.061h-8.428c-.442 0-.866.176-1.178.489L.844 13.438c-.65.652-.65 1.706 0 2.356l8.428 8.428c.65.65 1.705.65 2.356 0l11.06-11.06c.312-.314.485-.738.484-1.179V3.556a1.667 1.667 0 0 0-.06-.428l1.482-1.484a.833.833 0 0 0 .012-1.177zM21.51 11.983L10.45 23.044l-8.428-8.427L13.09 3.556h7.25L17.794 6.1a2.744 2.744 0 0 0-1.272-.322A2.778 2.778 0 1 0 19.3 8.556a2.744 2.744 0 0 0-.328-1.273l2.545-2.544-.006 7.244zm-3.889-3.427a1.111 1.111 0 1 1-1.11-1.112 1.111 1.111 0 0 1 1.116 1.112h-.006z" fill="currentColor" fillRule="evenodd"/></svg>
              </span>
              <p className="single-offer-overview__paragraph">
                { props.content.adultPrice }: €{props.priceAdult}
              </p>
            </li>

          </ul>
        </div>
      </section>
      <div className="single-offer-overview__separator"/>
    </Hoc>
  )
}

export default SingleOfferOverview;
