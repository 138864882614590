import React from 'react';

function SingleOfferHeader(props) {
   return (
    <div className={'single-offer-header ' + (props.type ? props.type : '')}>
      <div className="container">
      
      <div className="single-offer-header__wrapper">
        <div className="single-offer-header__heading-wrapper">
          <h2 className="single-offer-header__heading">
            {props.title}
          </h2>
        </div>
      </div>
</div>
    </div>
   )
}

export default SingleOfferHeader;