import React from "react";
import BookingForm from "./Form";

function Booking(props) {
  return (
    <article className="booking">
      <div className="booking__price-label">
        <p className="booking__price-label-text">{ props.content.price }</p>
      </div>
      <div className="booking__price-details">
        <span className="booking__price-icon">
          <svg width="25" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.271 1.029C18.886.386 18.114 0 17.214 0H3.071A2.579 2.579 0 0 0 .5 2.571V15.43A2.579 2.579 0 0 0 3.071 18h14.143c.9 0 1.672-.386 2.057-1.029L24.93 9 19.27 1.029zm-2.057 14.4H3.071V2.57h14.143L21.714 9l-4.5 6.429z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </span>
        <p className="booking__price-text">
          { props.content.from }{" "}
          <span className="booking__price-text--highlited">€{props.price}</span>
        </p>
      </div>

      <div className="booking__panel">
        <BookingForm tourTitle={props.title} content={props.content}/>
      </div>
    </article>
  );
}

export default Booking;
