import React from 'react';
import { Link } from 'react-router-dom';

import Support from '../Support/Support';

function CategoryList({ content, categories }) {
  return (
    <section className="category-list">
      <div className="container
                      container--flex"
      >
        <div className="category-list__wrapper">
          <header className="category-list__header">
            <h2 className="category-list__heading">
              <span className="category-list__icon">
                <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg"><path d="M5.78.87c0-.8-.65-.87-1.45-.87-.8 0-1.44.07-1.44.87V13h2.89V.87zm-2.9 24.26c0 .8.65.87 1.45.87.8 0 1.45-.07 1.45-.87v-4.9h-2.9v4.9zM7.8 14.44H.87c-.8 0-.87.65-.87 1.45v1.44c0 .8.07 1.45.87 1.45H7.8c.8 0 .87-.65.87-1.45V15.9c0-.8-.08-1.45-.87-1.45zm17.33 1.45H18.2c-.8 0-.87.65-.87 1.44v1.45c0 .8.07 1.44.87 1.44h6.93c.8 0 .87-.64.87-1.44v-1.45c0-.8-.07-1.44-.87-1.44zm-7.8-7.22c0-.8-.07-1.45-.86-1.45H9.53c-.8 0-.86.65-.86 1.45v1.44c0 .8.06 1.45.86 1.45h6.94c.8 0 .86-.65.86-1.45V8.67zM14.44.87C14.44.07 13.8 0 13 0c-.8 0-1.44.07-1.44.87v4.9h2.88V.88zm-2.88 24.26c0 .8.64.87 1.44.87.8 0 1.44-.07 1.44-.87V13h-2.88v12.13zM23.1.87c0-.8-.65-.87-1.44-.87-.8 0-1.45.07-1.45.87v13.57h2.9V.87zm-2.89 24.26c0 .8.65.87 1.45.87.8 0 1.44-.07 1.44-.87v-3.46h-2.89v3.46z" fill="currentColor" fillRule="nonzero"/></svg>
              </span>
              { content.heading }
            </h2>
          </header>
          <ul className="category-list__list">
            <li className="category-list__item">
              <Link
                to="/tours"
                className="category-list__link"
              >
                { categories.item1 }
              </Link>
            </li>
            <li className="category-list__item">
              <Link
                to="/auto-moto"
                className="category-list__link"
              >
                { categories.item2 }
              </Link>
            </li>
            <li className="category-list__item">
              <Link
                to="/motor-boat"
                className="category-list__link"
              >
                { categories.item3 }
              </Link>
            </li>
            <li className="category-list__item">
              <Link
                to="/cruises"
                className="category-list__link"
              >
                { categories.item4 }
              </Link>
            </li>
            <li className="category-list__item">
              <Link
                to="/transport"
                className="category-list__link"
              >
                { categories.item5 }
              </Link>
            </li>
            <li className="category-list__item">
              <Link
                to="/accommodation"
                className="category-list__link"
              >
                { categories.item6 }
              </Link>
            </li>
          </ul>
        </div>
        <Support />
      </div>
    </section>
  )
}

export default CategoryList;
