import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { Component } from "react";
import { connect } from "react-redux";
import "./scss/main.scss";
// import "./App.css";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home/Home";
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import SingleOfferPage from "./pages/SingleOfferPage/SingleOfferPage";
import Spinner from "./components/Spinner/Spinner";
import ContactPage from "./pages/ContactPage/ContactPage";
import { getLanguage } from "./store/languageActions";

class App extends Component {
  componentDidMount() {
    this.props.dispatch(getLanguage());
  }

  render() {
    return this.props.loadingLanguage ? (
      <Spinner />
    ) : (
      <BrowserRouter>
        <ScrollToTop>
          <Layout>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/contact" exact component={ContactPage} />
              <Route path="/:category" exact component={CategoryPage} />
              <Route path="/:category/:id" component={SingleOfferPage} />
            </Switch>
          </Layout>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingLanguage: state.loadingLanguage,
  };
};

export default connect(mapStateToProps)(App);
