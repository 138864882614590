import React from 'react';

function Spinner() {
  return (
    <div className="spinner">
      <ul className="spinner__list">
        <li className="spinner__single">
          <span className="spinner__dot
                          spinner__dot--1"
          ></span>
        </li>
        <li className="spinner__single">
          <span className="spinner__dot
                          spinner__dot--2"
          >  
          </span>
        </li>
        <li className="spinner__single">
          <span className="spinner__dot
                          spinner__dot--3"
          >  
          </span>
        </li>
      </ul>
    </div>  
  )
}

export default Spinner;