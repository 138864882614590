import React from 'react';

function Loading() {
  return (
    <div className="loading">
      <p className="loading__paragraph">
        Something went wrong, switch the category and try again.
      </p>
    </div>
  ) 
}

export default Loading;