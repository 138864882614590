import React from "react";

function VideoSection({ content }) {
  return (
    <section className="video-section">
      <div
        className="container
                      video-section--flex"
      >
        <div className="video__details">
          <h2 className="video__heading">
            <span className="video__icon">
              <svg width="31" height="26" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.2 23v-2c0-.55-.47-1-1.03-1H3.1c-.57 0-1.03.45-1.03 1v2c0 .55.46 1 1.03 1h2.07c.56 0 1.03-.45 1.03-1zm0-6v-2c0-.55-.47-1-1.03-1H3.1c-.57 0-1.03.45-1.03 1v2c0 .55.46 1 1.03 1h2.07c.56 0 1.03-.45 1.03-1zm0-6V9c0-.55-.47-1-1.03-1H3.1c-.57 0-1.03.45-1.03 1v2c0 .55.46 1 1.03 1h2.07c.56 0 1.03-.45 1.03-1zm16.53 12v-8c0-.55-.46-1-1.03-1H9.3c-.57 0-1.03.45-1.03 1v8c0 .55.46 1 1.03 1h12.4c.57 0 1.03-.45 1.03-1zM6.2 5V3c0-.55-.47-1-1.03-1H3.1c-.57 0-1.03.45-1.03 1v2c0 .55.46 1 1.03 1h2.07c.56 0 1.03-.45 1.03-1zm22.73 18v-2c0-.55-.46-1-1.03-1h-2.07c-.56 0-1.03.45-1.03 1v2c0 .55.47 1 1.03 1h2.07c.57 0 1.03-.45 1.03-1zm-6.2-12V3c0-.55-.46-1-1.03-1H9.3c-.57 0-1.03.45-1.03 1v8c0 .55.46 1 1.03 1h12.4c.57 0 1.03-.45 1.03-1zm6.2 6v-2c0-.55-.46-1-1.03-1h-2.07c-.56 0-1.03.45-1.03 1v2c0 .55.47 1 1.03 1h2.07c.57 0 1.03-.45 1.03-1zm0-6V9c0-.55-.46-1-1.03-1h-2.07c-.56 0-1.03.45-1.03 1v2c0 .55.47 1 1.03 1h2.07c.57 0 1.03-.45 1.03-1zm0-6V3c0-.55-.46-1-1.03-1h-2.07c-.56 0-1.03.45-1.03 1v2c0 .55.47 1 1.03 1h2.07c.57 0 1.03-.45 1.03-1zM31 2.5v21c0 1.38-1.16 2.5-2.58 2.5H2.58A2.55 2.55 0 0 1 0 23.5v-21C0 1.12 1.16 0 2.58 0h25.84A2.55 2.55 0 0 1 31 2.5z"
                  fill="currentColor"
                  fillRule="nonzero"
                />
              </svg>
            </span>
            { content.heading }
          </h2>
          <p className="video__description-text">
            { content.description }
          </p>
        </div>
        <div className="video__wrapper">
          <iframe
            src="https://www.youtube.com/embed//YgFVksp3d6c"
            frameBorder="0"
            allowFullScreen
            title="Video"
          />
        </div>
      </div>
    </section>
  );
}

export default VideoSection;
