import React from 'react';
import { Link } from 'react-router-dom';
import PopularServicesItem from '../PopularServicesItem/PopularServicesItem';

function PopularServices({ content }) {
  return (
    <section className="popular-services">
      <div className="popular-services__section-wrapper">
        <div className="container">
          <header className="popular-services__header">
            <h2 className="popular-services__heading">
              <span className="popular-services__icon">
                <svg width="28" height="23" xmlns="http://www.w3.org/2000/svg"><path d="M25.7 1.9a7.37 7.37 0 0 0-9.85 0L14 3.6l-1.85-1.7a7.37 7.37 0 0 0-9.85 0 6.88 6.88 0 0 0 0 10.26L14 23l11.7-10.84a6.89 6.89 0 0 0 0-10.27z" fill="currentColor" fillRule="nonzero"/></svg>
              </span>
              { content.heading }
            </h2>
            <Link
              to="/tours"
              className="popular-services__see-all"
            >
              <span className="popular-services__indicator">
                /
              </span>
              { content.link }
            </Link>
          </header>
          <div className="popular-services__description">
            <p className="popular-services__description-text">
              { content.description }
            </p>
          </div>
        </div>
      </div>
      <ul className="popular-services__list">
        <PopularServicesItem
          variant="popular-services__item--a"
          title={content.items.item1.title}
          linkText={content.items.item1.linkText}
          linkLabel={content.linkLabel}
          category="motor-boat"
        />
        <PopularServicesItem
          variant="popular-services__item--b"
          offerId="4"
          title={content.items.item2.title}
          category="tours"
          linkLabel={content.linkLabel}
        />
        <PopularServicesItem
          variant="popular-services__item--tours"
          title={content.items.item3.title}
          linkText={content.items.item3.linkText}
          linkLabel={content.linkLabel}
          category="tours"
        />
        <PopularServicesItem
          variant="popular-services__item--scuba-tour"
          offerId="5"
          title={content.items.item4.title}
          category="tours"
          linkLabel={content.linkLabel}
        />
        <PopularServicesItem
          variant="popular-services__item--auto-moto"
          title={content.items.item5.title}
          linkText={content.items.item5.linkText}
          linkLabel={content.linkLabel}
          category="auto-moto"
        />
      </ul>
    </section>
  )
}

export default PopularServices;
