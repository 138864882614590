import React from 'react';

function FooterCopyright() {
  return (
    <div className="footer__copyright">
      <p className="footer__text-copyright
                    u-text-center"
      >
        Copyright 2018 Abbacruises. All Right Reserved
      </p>
    </div>
  )
}

export default FooterCopyright;