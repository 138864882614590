import React from "react";

export default class BookingForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status } = this.state;
    return (
      <form
        className="booking__form"
        onSubmit={this.submitForm}
        action="https://formspree.io/f/xknpbojj"
        method="POST"
      >
        <div className="booking__form-header">
          <h3 className="booking__form-heading">{ this.props.content.form }</h3>
        </div>
        <input type="hidden" name="_subject" value="New booking request!" />
        <input type="hidden" name="Tour" value={`${this.props.tourTitle}`} />
        <div className="booking__form-single-row">
          <label htmlFor="full-name" className="booking__form-label">
            {this.props.content.name}*
          </label>
          <input
            type="text"
            className="booking__form-input"
            id="full-name"
            name="FullName"
            required
          />
        </div>
        <div className="booking__form-single-row">
          <label htmlFor="email" className="booking__form-label">
            {this.props.content.email}*
          </label>
          <input
            type="email"
            className="booking__form-input"
            id="email"
            name="BookingEmail"
            required
          />
        </div>
        <div className="booking__form-single-row">
          <label htmlFor="tel" className="booking__form-label">
            {this.props.content.phone}*
          </label>
          <input
            type="tel"
            className="booking__form-input"
            id="tel"
            name="PhoneNumber"
            minLength="4"
            maxLength="14"
            required
          />
        </div>
        <div className="booking__form-single-row">
          <label htmlFor="date" className="booking__form-label">
            {this.props.content.date}*
          </label>
          <input
            type="date"
            className="booking__form-input"
            id="date"
            name="BookingDate"
            required
          />
        </div>
        <div className="booking__form-single-row">
          <label htmlFor="number" className="booking__form-label">
            {this.props.content.persons}*
          </label>
          <input
            type="number"
            className="booking__form-input"
            id="number"
            name="People"
            min="1"
            max="30"
            required
          />
        </div>
        <div className="booking__form-single-row u-margin-bottom-small">
          <label htmlFor="message" className="booking__form-label">
            {this.props.content.message}*
          </label>
          <textarea
            name="BookingMessage"
            id="message"
            cols="30"
            rows="10"
            className="booking__form-text-area"
            required
          />
        </div>
        {status === "SUCCESS" ? (
          <div className="booking__form-single-row">
            <p className="booking__form-message popular-tours__item-heading">
              {this.props.content.successMessage}
            </p>
          </div>
        ) : (
          <div className="booking__form-single-row">
            <button
              className="booking__form-submit-btn
          button button--submit"
              type="submit"
            >
              {this.props.content.submit}
            </button>
          </div>
        )}
        {status === "ERROR" && (
          <div className="booking__form-single-row">
            <p className="booking__form-message popular-tours__item-heading">
              {this.props.content.errorMessage}
            </p>
          </div>
        )}
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
