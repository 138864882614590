import React, { Component } from "react";
import { connect } from "react-redux";
import {getLanguage} from "../../../store/languageActions";

class ToolbarLanguages extends Component {
  getLanguage(ln) {
    if (this.props.language === ln) {
      return;
    }
    this.props.onLanguageChange(ln);
  }

  render() {
    return (
      <div className="toolbar__languages">
        <button
          type="button"
          className="toolbar__languages-single"
          onClick={() => this.getLanguage("pl")}
        >
          <span
            className="toolbar__icon
                            toolbar__icon-language"
          >
            <svg width="20" height="15" xmlns="http://www.w3.org/2000/svg">
              <g fillRule="nonzero" fill="none">
                <path fill="#FFF" d="M20 13.13H0V0h20z" />
                <path fill="#DC143C" d="M20 15H0V7.5h20z" />
              </g>
            </svg>
          </span>
        </button>
        <button
          type="button"
          className="toolbar__languages-single"
          onClick={() => this.getLanguage("en")}
        >
          <span
            className="toolbar__icon
                    toolbar__icon-language"
          >
            <svg width="22" height="15" xmlns="http://www.w3.org/2000/svg">
              <g fillRule="nonzero" fill="none">
                <path fill="#00247D" d="M1 2h20v11H1z" />
                <path
                  d="M1 2l20 11m0-11L1 13"
                  stroke="#FFF"
                  strokeWidth="3"
                  fill="#000"
                />
                <path
                  d="M11 2v11M1 7.5h20"
                  stroke="#FFF"
                  strokeWidth="5"
                  fill="#000"
                />
                <path
                  d="M11 2v11M1 7.5h20"
                  stroke="#CF142B"
                  strokeWidth="3"
                  fill="#000"
                />
              </g>
            </svg>
          </span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLanguageChange: (language) =>
      dispatch(getLanguage(language)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarLanguages);
