import React from 'react';

function Sidebar(props) {
  return (
    <aside className={'sidebar ' + (props.class ? props.class : '')}>
      {props.children}
    </aside>
  )
}

export default Sidebar;