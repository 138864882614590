import React, { Component } from "react";
import { connect } from "react-redux";

import Hoc from "../../hoc/hoc";
import SingleOfferHeader from "../../components/SingleOfferHeader/SingleOfferHeader";
import SingleOffer from "../../components/SingleOffer/SingleOffer";
import Sidebar from "../../components/SideBar/SideBar";
import FeaturesList from "../../components/FeaturesList/FeaturesList";
import Support from "../../components/Support/Support";
import SingleOfferOverview from "../../components/SingleOfferOverview/SingleOfferOverview";
import Booking from "../../components/Booking/Booking";
import Loading from "../../components/Loading/Loading";

class SingleOfferPage extends Component {
  state = {
    loadedOffer: null,
  };

  getSingleOffer = () => {
    let offerId = parseInt(this.props.match.params.id);

    if (this.state.loadedOffer && this.state.loadedOffer.id === offerId) {
      return;
    }
    const offers = this.props.offers;
    const offerIndex = offers.findIndex((offer) => offer.id === offerId);
    this.setState({
      loadedOffer: offers[offerIndex],
    });
  };

  componentDidMount() {
    this.getSingleOffer();
  }

  render() {
    const { loadedOffer } = this.state;
    let offerToDisplay;

    if (this.props.match.params.id) {
      offerToDisplay = <Loading />;
    }

    if (loadedOffer) {
      offerToDisplay = (
        <Hoc>
          <SingleOfferHeader
            type={loadedOffer.header}
            title={loadedOffer.title}
          />
          <SingleOfferOverview
            time={loadedOffer.time}
            priceChild={loadedOffer.priceChild}
            priceAdult={loadedOffer.priceAdult}
            maxPeople={loadedOffer.maxPeople}
            content={this.props.currentContent.singleOffer.overview}
          />
          <div className="single-offer-page">
            <div
              className="container
                              container--flex
                              container--wrap"
            >
              <SingleOffer
                details={loadedOffer.details}
                includes={loadedOffer.includes}
                excludes={loadedOffer.excludes}
                toExpect={loadedOffer.toExpect}
                hours={loadedOffer.hours}
                departure={loadedOffer.departure}
                return={loadedOffer.return}
                imgs={loadedOffer.imgs}
                flayers={loadedOffer.flayers}
                content={this.props.currentContent.singleOffer.offer}
              />
              <Sidebar>
                <Booking
                  price={loadedOffer.priceAdult}
                  title={loadedOffer.title}
                  content={this.props.currentContent.booking}
                />
                <div className="single-offer-page__sidebar-wrapper">
                  <FeaturesList classes="sidebar__features--single-offer" />
                  <Support />
                </div>
              </Sidebar>
            </div>
          </div>
        </Hoc>
      );
    }

    return offerToDisplay;
  }
}

const mapStateToProps = (state) => {
  return {
    offers: state.offers,
    currentContent: state.currentContent,
  };
};

export default connect(mapStateToProps)(SingleOfferPage);
