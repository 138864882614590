import React from 'react';
import { Link } from 'react-router-dom';

function Button(props) {

  return (
    <Link
      to={props.link}
      className={'button ' + (props.classes ? props.classes : '')}
      onClick={props.onClick ? props.onClick : null}
    >
      {props.children}
    </Link>
  )
}

export default Button;