import React from "react";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function CategoryAllItem(props) {
  const prevPrice = props.promo
    ? Math.floor(props.price * (100 / (100 - props.discount)))
    : 0;

  return (
    <li
      className={
        "category-all-items__item " +
        (props.promo ? "category-all-items__item--promo" : "")
      }
    >
      {props.link ? (
        <a href={props.link} className="category-all-items__item-img-link">
          <div
            className={
              "category-all-items__item-img " + (props.type ? props.type : "")
            }
          >
            <div className="category-all-items__item-promo-label">
              <span className="category-all-items__item-promo-text">
                {props.discount}% { props.currentContent.offersPage.off }
              </span>
            </div>
          </div>
        </a>
      ) : (
        <Link
          to={`/${props.category}/${props.tourId}`}
          className="category-all-items__item-img-link"
          onClick={props.getOnClick}
        >
          <div
            className={
              "category-all-items__item-img " + (props.type ? props.type : "")
            }
          >
            <div className="category-all-items__item-promo-label">
              <span className="category-all-items__item-promo-text">
                {props.discount}% { props.currentContent.offersPage.off }
              </span>
            </div>
          </div>
        </Link>
      )}

      <div className="category-all-items__item-details">
        <div className="category-all-items__item-description">
          {props.link ? (
            <a
              href={props.link}
              className="category-all-items__item-heading-link"
            >
              <h3 className="category-all-items__item-heading u-margin-bottom-small">
                {props.title}
              </h3>
            </a>
          ) : (
            <Link
              to={`/${props.category}/${props.tourId}`}
              className="category-all-items__item-heading-link"
              onClick={props.getOnClick}
            >
              <h3 className="category-all-items__item-heading u-margin-bottom-small">
                {props.title}
              </h3>
            </Link>
          )}

          <div className="category-all-items__item-feature">
            <span className="category-all-items__item-feature-icon">
              <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.51 15.37l-4.32-4.33V5.25h2.62v4.7l3.56 3.56-1.86 1.86zM10.5 0a10.5 10.5 0 1 0 0 21 10.5 10.5 0 0 0 0-21zm0 18.38a7.88 7.88 0 1 1 0-15.76 7.88 7.88 0 0 1 0 15.75z"
                  fill="#6F6CB0"
                  fillRule="nonzero"
                />
              </svg>
            </span>
            <span className="category-all-items__item-feature-label">
              {props.time} { props.currentContent.offersPage.time }
            </span>
          </div>
          <p className="category-all-items__paragraph">{props.description}</p>
        </div>
        <div className="category-all-items__item-pricing">
          <div className="category-all-items__item-price-wrapper">
            <span className="category-all-items__item-price-label">
              { props.currentContent.offersPage.from }
            </span>
            {props.promo ? (
              <span className="category-all-items__item-price--previous">
                €{prevPrice}
              </span>
            ) : null}
            <p className="category-all-items__item-price">€{props.price}</p>
          </div>
          <div className="category-all-items__item-review">
            <span className="category-all-items__item-stars-single">
              <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 5.04L8.5 4.4 6.5.32l-2 4.07-4.5.65 3.25 3.17-.77 4.47 4.02-2.11 4.02 2.11-.77-4.47z"
                  fill="#ffa127"
                  fillRule="nonzero"
                />
              </svg>
            </span>
            <span className="category-all-items__item-stars-single">
              <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 5.04L8.5 4.4 6.5.32l-2 4.07-4.5.65 3.25 3.17-.77 4.47 4.02-2.11 4.02 2.11-.77-4.47z"
                  fill="#ffa127"
                  fillRule="nonzero"
                />
              </svg>
            </span>
            <span className="category-all-items__item-stars-single">
              <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 5.04L8.5 4.4 6.5.32l-2 4.07-4.5.65 3.25 3.17-.77 4.47 4.02-2.11 4.02 2.11-.77-4.47z"
                  fill="#ffa127"
                  fillRule="nonzero"
                />
              </svg>
            </span>
            <span className="category-all-items__item-stars-single">
              <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 5.04L8.5 4.4 6.5.32l-2 4.07-4.5.65 3.25 3.17-.77 4.47 4.02-2.11 4.02 2.11-.77-4.47z"
                  fill="#ffa127"
                  fillRule="nonzero"
                />
              </svg>
            </span>
            <span className="category-all-items__item-stars-single">
              <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 5.04L8.5 4.4 6.5.32l-2 4.07-4.5.65 3.25 3.17-.77 4.47 4.02-2.11 4.02 2.11-.77-4.47L13 5.04zM6.5 9.57l-2.84 1.49.55-3.16-2.3-2.24 3.17-.46L6.5 2.33 7.92 5.2l3.17.46L8.8 7.9l.55 3.16-2.84-1.5z"
                  fill="#ffa127"
                  fillRule="nonzero"
                />
              </svg>
            </span>
          </div>

          {props.link ? (
            <a
              href={props.link}
              className="category-all-items__link
                            button--small"
              target="_blank"
              rel="noreferrer"
            >
              { props.currentContent.buttons.details }
            </a>
          ) : (
            <Button
              classes="button--small"
              link={`/${props.category}/${props.tourId}`}
              onClick={props.getOnClick}
            >
              { props.currentContent.buttons.details }
            </Button>
          )}
        </div>
      </div>
    </li>
  );
}

const mapStateToProps = state => {
  return {
    currentContent: state.currentContent,
  }
}

export default connect(mapStateToProps)(CategoryAllItem);
