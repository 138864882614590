import React from 'react';
import NavItem from '../NavItem/NavItem';
import { connect } from "react-redux";

function Nav(props) {
  return (
    <nav className="nav">
      {props.currentContent.nav &&
      <ul className="nav__list">
        <NavItem
          category="tours"
          class="nav__link"
          classActive="nav__link--active"
        >
          { props.currentContent.nav.item1 }
        </NavItem>
        <NavItem
          category="auto-moto"
          class="nav__link"
          classActive="nav__link--active"
        >
          { props.currentContent.nav.item2 }
        </NavItem>
        <NavItem
          category="motor-boat"
          class="nav__link"
          classActive="nav__link--active"
        >
          { props.currentContent.nav.item3 }
        </NavItem>
        <NavItem
          category="cruises"
          class="nav__link"
          classActive="nav__link--active"
        >
          { props.currentContent.nav.item4 }
        </NavItem>
        <NavItem
          category="transport"
          class="nav__link"
          classActive="nav__link--active"
        >
          { props.currentContent.nav.item5 }
        </NavItem>
        <NavItem
          category="accommodation"
          class="nav__link"
          classActive="nav__link--active"
        >
          { props.currentContent.nav.item6 }
        </NavItem>
        <NavItem
          category="contact"
          class="nav__link"
          classActive="nav__link--active"
        >
          { props.currentContent.nav.item7 }
        </NavItem>
      </ul>
      }
    </nav>
  )
}

const mapStateToProps = state => {
  return {
    currentContent: state.currentContent,
  }
}

export default connect(mapStateToProps)(Nav);
