import React, { Component } from "react";

class WhatsappButton extends Component {
  state = {
    showMessage: false,
    showButtons: false,
  }

  handleToggleButtons = () => {
    this.setState((prevState) => ({ showButtons: !prevState.showButtons, showMessage: false }));
  }


  render() {
    const { showButtons } = this.state;
    return (
      <div className="whatsapp-button__wrapper">
        {
          showButtons ?
            <div className="whatsapp-button__floating-group">
              <a
                href={`https://api.whatsapp.com/send?phone=${this.props.phoneNumber}`}
                target="_blank"
                rel="noreferrer"
                className="whatsapp-button heading-tertiary whatsapp-button--sub"
              >
          <span className="icon">
            <svg width="60" height="60" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30 60c16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30z" fill="#F0F0F0"/><path d="M6.202 11.735a29.89 29.89 0 00-5.168 10.44H16.64L6.202 11.734zM58.967 22.174a29.893 29.893 0 00-5.168-10.438L43.36 22.174h15.607zM1.034 37.826a29.893 29.893 0 005.168 10.439L16.64 37.826H1.034zM48.265 6.202a29.893 29.893 0 00-10.439-5.169V16.64L48.265 6.202zM11.736 53.798a29.893 29.893 0 0010.438 5.168V43.36L11.736 53.798zM22.174 1.033a29.894 29.894 0 00-10.438 5.169L22.174 16.64V1.033zM37.827 58.966a29.892 29.892 0 0010.438-5.168L37.827 43.36v15.606zM43.36 37.826L53.8 48.265a29.892 29.892 0 005.168-10.439H43.36z" fill="#0052B4"/><path d="M59.746 26.087H33.913V.254a30.3 30.3 0 00-7.826 0v25.833H.254a30.3 30.3 0 000 7.826h25.833v25.833a30.308 30.308 0 007.826 0V33.913h25.833a30.308 30.308 0 000-7.826z" fill="#D80027"/><path d="M37.826 37.826l13.387 13.387a30.041 30.041 0 001.764-1.926l-11.461-11.46h-3.69zM22.174 37.826L8.787 51.213a30.088 30.088 0 001.926 1.764l11.46-11.462v-3.689zM22.174 22.174L8.787 8.787a30.081 30.081 0 00-1.764 1.926l11.462 11.46h3.689v.001zM37.826 22.174L51.213 8.787a30.002 30.002 0 00-1.926-1.764l-11.46 11.462v3.689z" fill="#D80027"/></svg>
          </span>
                <span className="visually-hidden">Call Us!</span>
              </a>
              {this.props.polishNumber ? <a
                href={`https://api.whatsapp.com/send?phone=${this.props.polishNumber}`}
                target="_blank"
                rel="noreferrer"
                className="whatsapp-button heading-tertiary whatsapp-button--sub"
              >
          <span className="icon">
            <svg width="60" height="60" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60"><circle cx="30" cy="30" r="30" fill="#C4C4C4"/></mask><g mask="url(#a)"><path d="M70 52.52h-80V0h80v52.52z" fill="#fff"/><path d="M70 60h-80V30h80v30z" fill="#DC143C"/></g></svg>
          </span>
                <span className="visually-hidden">Call Us!</span>
              </a> : null}
            </div> : null
        }
        <button
          className="whatsapp-button heading-tertiary whatsapp-button--animation"
          onClick={this.handleToggleButtons}
        >
          <span className="icon">
            <svg
              width="43"
              height="43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.423 6.515A20.77 20.77 0 0021.635.385C10.112.384.735 9.761.73 21.287a20.868 20.868 0 002.79 10.451L.555 42.572l11.082-2.907a20.883 20.883 0 009.99 2.544h.009c11.521 0 20.9-9.378 20.904-20.905a20.777 20.777 0 00-6.117-14.789zM21.635 38.68h-.007a17.354 17.354 0 01-8.844-2.422l-.634-.377-6.576 1.725 1.755-6.411-.413-.658A17.333 17.333 0 014.26 21.29c.003-9.58 7.798-17.374 17.382-17.374a17.257 17.257 0 0112.284 5.096 17.272 17.272 0 015.085 12.292c-.004 9.581-7.799 17.376-17.376 17.376zm9.531-13.014c-.522-.261-3.09-1.524-3.57-1.7-.478-.173-.826-.26-1.174.262-.349.523-1.35 1.7-1.654 2.048-.305.349-.61.393-1.132.131-.522-.261-2.205-.813-4.2-2.592-1.553-1.386-2.602-3.096-2.907-3.619-.304-.523-.002-.779.23-1.066.565-.702 1.131-1.438 1.305-1.787.175-.348.087-.653-.043-.915-.13-.261-1.175-2.832-1.61-3.878-.425-1.018-.855-.88-1.176-.897a20.867 20.867 0 00-1.001-.018c-.348 0-.914.13-1.393.654-.479.523-1.828 1.786-1.828 4.357s1.872 5.055 2.133 5.404c.26.348 3.683 5.624 8.923 7.887 1.246.538 2.219.86 2.978 1.1 1.251.398 2.39.342 3.29.207 1.003-.15 3.09-1.264 3.526-2.484.435-1.22.435-2.265.304-2.484-.13-.217-.479-.348-1-.61z"
                fill="currentColor"
              />
            </svg>
          </span>
          <span className="visually-hidden">Call Us!</span>
        </button>
        <div
          className={"whatsapp-button__message " + (this.state.showMessage ? "show" : "")}
          onClick={this.handleCloseMessage}
        >
          <div className="arrow-right"/>
          <p className="features__paragraph">
            Give us a call and ask for the best deal!
          </p>
        </div>
      </div>
    );

  }
}

export default WhatsappButton;
