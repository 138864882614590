import React from 'react';
import { Link } from 'react-router-dom';

function PopularTourItem(props) {
  const { offer } = props;

  if(!offer) {
    return <p>WAIT</p>
  } else {
    const prevPrice = offer.promo ? Math.floor(offer.priceAdult * (100/(100 - offer.discount))) : 0;

    return (
      <div className={"popular-tours__item " + (offer.promo ? "popular-tours__item--promo" : "")}
      >
        <Link 
          to={`/tours/${offer.id}`} 
          className="popula-tours__link"
        >
          <div className={'popular-tours__item-image ' + (offer.listHeader? offer.listHeader : '')}>
            <div className="popular-tours__item-promo-label">
              <span className="popular-tours__item-promo-text">
                {offer.discount}% Off
              </span>
            </div>
          </div>
          <div className="popular-tours__item-details">
            <div className="popular-tours__item-header">
              <h3 className="popular-tours__item-text-heading">
                {offer.title}
              </h3>
              <div className="popular-tours__item-price-wrapper">
                {
                  offer.promo ? 
                    <span className="popular-tours__item-price--previous">
                      €{prevPrice}
                    </span>
                    :
                    null
                }
                <span className="popular-tours__item-price">
                  €{offer.priceAdult}
                </span>
              </div>
            </div>
            
            <div className="popular-tours__item-duration">
              <span className="popular-tours__icon">
                <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg"><path d="M13.51 15.37l-4.32-4.33V5.25h2.62v4.7l3.56 3.56-1.86 1.86zM10.5 0a10.5 10.5 0 1 0 0 21 10.5 10.5 0 0 0 0-21zm0 18.38a7.88 7.88 0 1 1 0-15.76 7.88 7.88 0 0 1 0 15.75z" fill="#6F6CB0" fillRule="nonzero"/></svg>
              </span>
              <span className="popular-tours__item-label">
                {offer.time} Hours
              </span>
            </div>
            <div className="popular-tours__item-review">
              <span className="popular-tours__item-stars-single">
                <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M13 5.04L8.5 4.4 6.5.32l-2 4.07-4.5.65 3.25 3.17-.77 4.47 4.02-2.11 4.02 2.11-.77-4.47z" fill="#ffa127" fillRule="nonzero"/></svg>
              </span>
              <span className="popular-tours__item-stars-single">
                <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M13 5.04L8.5 4.4 6.5.32l-2 4.07-4.5.65 3.25 3.17-.77 4.47 4.02-2.11 4.02 2.11-.77-4.47z" fill="#ffa127" fillRule="nonzero"/></svg>
              </span>
              <span className="popular-tours__item-stars-single">
                <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M13 5.04L8.5 4.4 6.5.32l-2 4.07-4.5.65 3.25 3.17-.77 4.47 4.02-2.11 4.02 2.11-.77-4.47z" fill="#ffa127" fillRule="nonzero"/></svg>
              </span>
              <span className="popular-tours__item-stars-single">
                <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M13 5.04L8.5 4.4 6.5.32l-2 4.07-4.5.65 3.25 3.17-.77 4.47 4.02-2.11 4.02 2.11-.77-4.47z" fill="#ffa127" fillRule="nonzero"/></svg>
              </span>
              <span className="popular-tours__item-stars-single">
                <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M13 5.04L8.5 4.4 6.5.32l-2 4.07-4.5.65 3.25 3.17-.77 4.47 4.02-2.11 4.02 2.11-.77-4.47L13 5.04zM6.5 9.57l-2.84 1.49.55-3.16-2.3-2.24 3.17-.46L6.5 2.33 7.92 5.2l3.17.46L8.8 7.9l.55 3.16-2.84-1.5z" fill="#ffa127" fillRule="nonzero"/></svg>
              </span>
            </div>
          </div>
        </Link>
      </div>
    )
  }
   
}

export default PopularTourItem;