import React from 'react';
import CategoryAllItem from '../CategoryAllItem/CategoryAllItem';

function CategoryAllItems(props) {

  const allOffers = props.allOffers.map(single => <CategoryAllItem 
          key={single.id}
          promo={single.promo}
          title={single.title}
          tourId={single.id}
          category={single.categoryId}
          time={single.time}
          price={single.priceAdult}
          description={single.description}
          type={single.listHeader}
          discount={single.discount}
          img={single.imgs}
          link={single.link}
        />
  )

  return (
    <section className="category-all-items">
      <ul className="category-all-items__list">

        {allOffers}

      </ul>
    </section>
  )
}

export default CategoryAllItems;