import {
  FETCH_LANGUAGE_BEGIN,
  FETCH_LANGUAGE_SUCCESS,
  FETCH_LANGUAGE_FAILURE,
} from "./languageActions";

const initalState = {
  offers: [],
  categories: [],
  currentContent: [],
  loadingLanguage: true,
  errorLanguage: null,
  categoryToDisplay: null,
  language: 'en',
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case "OFFER_TO_LOAD":
      return {
        ...state,
        offerToDisplay: action.offerId,
      };
    case FETCH_LANGUAGE_BEGIN:
      return {
        ...state,
        loadingLanguage: true,
        errorLanguage: null,
      };
    case FETCH_LANGUAGE_SUCCESS:
      return {
        ...state,
        loadingLanguage: false,
        currentContent: action.payload.content.translations,
        language: action.payload.language,
        offers: action.payload.content.offers,
        categories: action.payload.content.categories,
      };
    case FETCH_LANGUAGE_FAILURE:
      return {
        ...state,
        loadingLanguage: false,
        currentContent: [],
        errorLanguage: action.payload.errorLanguage,
      };
    default:
      return state;
  }
};

export default reducer;
