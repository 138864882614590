import React from 'react';

function CategoryHeader(props) {
  return (
    <div className={'category-header ' + (props.type ? props.type : '')}>
      <div className="container">
        <div className="category-header__wrapper">
          <h2 className="category-header__heading">
            {props.title}
          </h2>
        </div>
      </div>
    </div>
  )
}

export default CategoryHeader;