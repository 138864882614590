import React from 'react';
import ListIconItem from '../FeatureItem/ListIconItem';

function Featurs({ content }) {
  return (
    <section className="features">
      <div className="container">
        <ul className="features__list">
          <ListIconItem
            itemClass="features__item"
            title={content.feature1.title}
            content={content.feature1.content}
          >
            <svg width="47" height="49" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor" fillRule="evenodd"><path d="M5.477 30.136h10.114A3.41 3.41 0 0 0 19 26.727V9.864a3.41 3.41 0 0 0-6.33-1.75L2.557 24.977a3.41 3.41 0 0 0 2.92 5.16zM15.591 9.773v16.954H5.477L15.591 9.773z"/><path d="M46.239 34.33a3.41 3.41 0 0 0-3.069-1.92H24.682v-2.274h14.773a3.41 3.41 0 0 0 2.875-5.238L27.523 1.728a3.41 3.41 0 0 0-5.682-.137c-.394.32-.623.8-.625 1.307v29.545H4.17a3.41 3.41 0 0 0-2.727 5.455l6.818 9.09a3.41 3.41 0 0 0 2.728 1.364h25a3.41 3.41 0 0 0 2.681-1.307l7.125-9.09a3.41 3.41 0 0 0 .444-3.625zm-6.819-7.603H24.648V3.761L39.42 26.727zM36.011 44.91h-25l-6.818-9.09H42.99l-6.978 9.09z"/></g></svg>
          </ListIconItem>
          <ListIconItem
            itemClass="features__item"
            title={content.feature2.title}
            content={content.feature2.content}
          >
            <svg width="45" height="39" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor" fillRule="evenodd"><path d="M41.72 12.4v-9a3 3 0 0 0-3-3h-35a3 3 0 0 0-3 3v32a3 3 0 0 0 3 3h35a3 3 0 0 0 3-3v-9a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3zm-3 23h-35v-32h35v9h-5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h5v9zm3-12h-8v-8h8v8z"/><circle cx="37.72" cy="19.4" r="1.5"/></g></svg>
          </ListIconItem>
          <ListIconItem
            itemClass="features__item"
            title={content.feature3.title}
            content={content.feature3.content}
          >
            <svg width="45" height="31" xmlns="http://www.w3.org/2000/svg"><path d="M42.56 18.4a3 3 0 0 0 0-1.9c-1-3-3.86-4.24-6.07-4.73-.05-.31-.11-.6-.17-.87a5 5 0 0 0-3.61-8.45 5 5 0 0 0-4-2h-2a3 3 0 0 0-3 3v.18l-2.86-.8a1.5 1.5 0 1 0-.81 2.89l4 1.12a3 3 0 0 0 .31.48c1.58 2 3.34 5 3.34 6.13 0 1.94-1.3 4-2 4a1.58 1.58 0 0 1-.83-.37 3.28 3.28 0 0 1-1.21-2.38 7.46 7.46 0 0 0-5-6.1 3 3 0 0 0-.95-.15h-6C4.82 8.45 1.27 17.38.89 18.4a3 3 0 0 0 2.83 4h.08a7 7 0 0 0 12.67 5h15.51a7 7 0 1 0 10.57-9h.01zm-31.84 9a4 4 0 1 1 0-8h.31l-1.65 3.3a1.5 1.5 0 0 0 2.68 1.34l1.65-3.3a4 4 0 0 1-3 6.63l.01.03zm6.92-3a7 7 0 0 0-12.3-5.47c-.52.13-1.05.28-1.62.47a19.52 19.52 0 0 1 3.52-5.73 1.48 1.48 0 0 0 0 .23 1.5 1.5 0 0 0 1.5 1.5h8a1.5 1.5 0 0 0 0-3h-8a5.32 5.32 0 0 1 3-1h6a4.54 4.54 0 0 1 3 3.56 6.23 6.23 0 0 0 2.45 4.51 4.43 4.43 0 0 0 2.57.93c3 0 5-4 5-7 0-2.24-2.22-5.59-3.36-7.14v-.09a1.49 1.49 0 0 0-.68-1.69V3.4h2a2 2 0 0 1 2 2h2a2 2 0 1 1 0 4 12.13 12.13 0 0 1 1 5s4 0 5.57 2.18a7 7 0 0 0-8.49 7.82H17.64zm20.08 3a4 4 0 0 1-3-6.63l1.65 3.3a1.5 1.5 0 1 0 2.68-1.34l-1.65-3.3h.31a4 4 0 1 1 0 8l.01-.03z" fill="currentColor" fillRule="evenodd"/></svg>
          </ListIconItem>
          <ListIconItem
            itemClass="features__item"
            title={content.feature4.title}
            content={content.feature4.content}
          >
            <svg width="39" height="45" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor" fillRule="evenodd"><path d="M38.63 6.67C38.04 4.33 34.81.4 19.72.4c-7.11 0-12.27.78-15.34 2.32C.99 4.42.72 6.72.72 7.4v13.3a23 23 0 0 0 11.72 20l5.81 3.27a3 3 0 0 0 2.94 0L27 40.7a23 23 0 0 0 11.72-20V7.4a3 3 0 0 0-.09-.73zm-2.91 14A20 20 0 0 1 25.53 38.1l-5.81 3.3-5.81-3.27A20 20 0 0 1 3.72 20.7V7.4s0-4 16-4c15 0 16 4 16 4v13.27z"/><path d="M28.66 14.34l-9.94 9.94-5.94-5.94a1.5 1.5 0 0 0-2.12 2.12l7 7a1.5 1.5 0 0 0 2.12 0l11-11a1.5 1.5 0 0 0-2.12-2.12z"/></g></svg>
          </ListIconItem>
        </ul>
      </div>
    </section>
  )
}

export default Featurs;
