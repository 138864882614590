import React from 'react';
import Button from '../Button/Button';

function JoinUs({ content }) {
  return (
    <section className="join-us">
        <div className="container container--flex join-us-wrapper">
          <div className="join-us__description">
            <h2 className="join-us__heading u-margin-bottom-small">
              {content.heading} <span className="join-us__heading--highlited">{content.highlited}</span>
            </h2>
            <div className="join-us__description-text
                          u-margin-bottom-medium"
            >
              {content.description.map((item, index) => <p key={index}>{item}</p>)}
            </div>
            <Button
              classes="button--secondary test"
              link='/contact'
            >
              {content.contactUs}
            </Button>
          </div>
        </div>

    </section>
  )
}

export default JoinUs;
