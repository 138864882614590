import React from 'react';
import Logo from '../../Logo/Logo';

function FooterAbout({ content }) {
  return (
    <div className="footer__about
                    footer__single"
    >
      <div className="u-margin-bottom-medium">
        <Logo color="#3C2A98"/>
      </div>
      <p className="footer__text-paragraph">
        { content.about }
      </p>
    </div>
  )
}

export default FooterAbout;
