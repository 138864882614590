import React from 'react';
import { Link } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import { connect } from 'react-redux';

import PopularTourItem from '../PopularTourItem/PopularTourItem';

function PopularTours(props) {

  const { offers } = props;

  const params = {
    slidesPerView: 3,
    spaceBetween: 40,
    loop: false,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      991: {
        slidesPerView: 2
      },
      700: {
        slidesPerView: 1
      }
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    }
  };
  return (
    // TODO: How to load data to not to wait in every component.

    <section className="popular-tours">
      <div className="container">
        <header className="popular-tours__header">
          <h2 className="popular-tours__heading">
            { props.content.heading }
          </h2>
          <Link
            to="/tours"
            className="popular-tours__see-all"
          >
            <span className="popular-tours__indicator">
              /
            </span>
            { props.content.link }
          </Link>
        </header>

        {
          !offers ? <p>Wait</p>
            :
            (
              <Swiper {...params}>
                <div>
                  <PopularTourItem
                    offer={offers[3]}
                  />
                </div>
                <div>
                  <PopularTourItem
                    offer={offers[4]}
                  />
                </div>
                <div>
                  <PopularTourItem
                    offer={offers[1]}
                  />
                </div>
                <div>
                  <PopularTourItem
                    offer={offers[6]}
                  />
                </div>
                <div>
                  <PopularTourItem
                    offer={offers[8]}
                  />
                </div>
              </Swiper>
            )
        }
      </div>
    </section>
  )
}

const mapStateToProps = state => {
  return {
    offers: state.offers
  }
}

export default connect(mapStateToProps)(PopularTours);
