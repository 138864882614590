import React, { Component } from 'react';
import { connect } from 'react-redux';

import Hoc from '../../hoc/hoc';
import CategoryHeader from '../../components/CategoryHeader/CategoryHeader';
import CategoryAllItems from '../../components/CategoryAllItems/CategoryAllItems';
import Sidebar from '../../components/SideBar/SideBar';
import FeaturesList from '../../components/FeaturesList/FeaturesList';
import Support from '../../components/Support/Support';
import Loading from '../../components/Loading/Loading';

class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCategory: null,
      offersToDisplay: [],
      categoryId: ''
    }
  }

  getCategoryHandler = () => {
    const categoryToDisplay = this.props.match.params.category;
    const { categories } = this.props;

    const categoryIndex = categories.findIndex(category => category.category === categoryToDisplay);

    this.setState({
      currentCategory: categories[categoryIndex],
      categoryId: categoryToDisplay
    })
  }

  getOffersHandler = () => {
    const { offers } = this.props;
    const categoryToDisplay = this.props.match.params.category;
    
    const offersToDisplay = offers.filter(offer => offer.categoryId === categoryToDisplay);

    this.setState({
      offersToDisplay: offersToDisplay
    })
  }

  componentDidMount() {
    this.getCategoryHandler();
    this.getOffersHandler();
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.match.params.category !== this.props.match.params.category) {
      this.getCategoryHandler();
      this.getOffersHandler();
    } 

  }
  
  
  render() {
    const { currentCategory, offersToDisplay } = this.state;
    let toRender;

    if(this.props.match.params.category) {
      toRender = <Loading />
    }

    if(offersToDisplay && currentCategory) {
      toRender = (
        <Hoc>
          <CategoryHeader
            type={currentCategory.header}
            title={currentCategory.title}
          />
          <div className="category-page">
            <div className="container 
                            container--flex
                            container--wrap"
            >
              <CategoryAllItems 
                allOffers={offersToDisplay}
              />
              <Sidebar>
                <FeaturesList 
                  classes="sidebar__features--padding-bottom"
                />
                <Support />
              </Sidebar>
            </div>
            
          </div>
        </Hoc>
      )
    }

    return toRender;
  } 
}

const mapStateToProps = state => {
  return {
    categories: state.categories,
    offers: state.offers
  }
}

export default connect(mapStateToProps)(CategoryPage);