import React from 'react';
import { Link } from 'react-router-dom';

function PopularServicesItem(props) {
  return (
    <li className={`popular-services__item ${ props.variant }`}>
      <Link
        to={`/${props.category}/` + ( props.offerId ? props.offerId : '')}
        className="popular-services__item--wrapper"
      >
        <div className="popular-services__item-title">
          <h3 className="popular-services__item-heading">
            {props.title}
          </h3>
          {
            props.linkText ?
              <span className="popular-services__item-count">
                {props.linkText}
              </span> : null
          }
        </div>

        <span className="popular-services__link">
          { props.linkLabel }
        </span>
      </Link>
    </li>
  )
}

export default PopularServicesItem;
