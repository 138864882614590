import React, { Component } from "react";
import { connect } from "react-redux";

import Hoc from "../../hoc/hoc";
import Toolbar from "../Header/Toolbar/Toolbar";
import ToolbarContact from "../Header/ToolbarContact/ToolbarContact";
import ToolbarSocial from "../Header/ToolbarSocial/ToolbarSocial";
import ToolbarLanguages from "../Header/ToolbarLanguages/ToolbarLanguages";
import Logo from "../Logo/Logo";
import Nav from "../Header/Nav/Nav";
import NavMobile from "../Header/NavMobile/NavMobile";
import FooterAbout from "../Footer/FooterAbout/FooterAbout";
import FooterServices from "../Footer/FooterServices/FooterServices";
import FooterContact from "../Footer/FooterContact/FooterContact";
import FooterCopyright from "../Footer/FooterCopyright/FooterCopyright";
import WhatsappButton from "../WhatsappButton/WhatsappButton";

class Layout extends Component {
  state = {
    mobileBreakpoint: 991,
    isMobile: false,
  };

  resizeHandler = () => {
    const isMobile = window.innerWidth <= this.state.mobileBreakpoint;

    if (this.state.isMobile !== isMobile) {
      this.setState({ isMobile: isMobile });
    }
  };

  componentDidMount() {
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  render() {
    const { isMobile } = this.state;

    return (
      <Hoc>
        <header className="header">
          <div className="container">
            <Toolbar>
              {
                !isMobile &&
                  <>
                    <ToolbarContact />
                    <div className="header__wrapper">
                      <ToolbarSocial socialClass="toolbar__social-link--header" />
                      <ToolbarLanguages />
                    </div>
                  </>
              }
            </Toolbar>
            <Toolbar>
              <h1>
                <Logo color="#6F6CB0" />
                <span className="visually-hidden">Abbacruises</span>
              </h1>
              {isMobile ? <NavMobile /> : <Nav />}
            </Toolbar>
          </div>
        </header>
        <main className="main">{this.props.children}</main>
        <footer className="footer">
          <div
            className="container
                          footer__wrapper"
          >
            <FooterAbout content={this.props.currentContent.footer}/>
            <FooterServices content={this.props.currentContent.footer}/>
            <FooterContact content={this.props.currentContent.footer}/>
          </div>
          <FooterCopyright />
        </footer>
        <WhatsappButton phoneNumber="306977741500" />
        {/*<WhatsappButton phoneNumber="306977741500" polishNumber="0048514723103" />*/}
      </Hoc>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentContent: state.currentContent,
  }
}

export default connect(mapStateToProps)(Layout);
