import axios from "axios";
export const FETCH_LANGUAGE_BEGIN = "FETCH_LANGUAGE_BEGIN";
export const FETCH_LANGUAGE_SUCCESS = "FETCH_LANGUAGE_SUCCESS";
export const FETCH_LANGUAGE_FAILURE = "FETCH_LANGUAGE_FAILURE";

export function getLanguage(ln = "en") {
  let language = "";

  switch (ln) {
    case "pl":
      language = "abba-pl.json";
      break;
    case "en":
      language = "abba-en.json";
      break;
    default:
      break;
  }
  return (dispatch) => {
    dispatch(fetchLanguageBegin());
    return axios
      .get(`${process.env.PUBLIC_URL}/${language}`)
      .then((response) => {
        dispatch(fetchLanguageSuccess(response.data, ln));
      });
  };
}

export const fetchLanguageBegin = () => ({
  type: FETCH_LANGUAGE_BEGIN,
});

export const fetchLanguageSuccess = (content, language) => ({
  type: FETCH_LANGUAGE_SUCCESS,
  payload: { content, language },
});

export const fetchLanguageError = (errorLanguage) => ({
  type: FETCH_LANGUAGE_FAILURE,
  payload: { errorLanguage },
});
