import React from 'react';

function ToolbarContact() {
  return (
    <div className="toolbar__contact-info">
      <a href="tel:+306977741500"
        className="toolbar__contact-info-phone"
      >
        <span className="toolbar__icon
                          toolbar__icon-contact"
        >

          <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.27 8.4c-.94.93-.94 1.87-1.87 1.87-.93 0-1.87-.94-2.8-1.87-.93-.93-1.87-1.87-1.87-2.8 0-.93.94-.93 1.87-1.87C6.53 2.8 3.73 0 2.8 0 1.87 0 0 2.8 0 2.8c0 1.87 1.92 5.65 3.73 7.47C5.55 12.08 9.33 14 11.2 14c0 0 2.8-1.87 2.8-2.8 0-.93-2.8-3.73-3.73-2.8z" fill="#fff" fillRule="nonzero"/>
          </svg>
        </span>
        +306977741500
      </a>
      <a href="mailto:info@abbacruises.com"
        className="toolbar__contact-info-mail"
      >
        <span className="toolbar__icon
                          toolbar__icon-contact"
        >
          <svg width="14" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M7 5.25l7-3.45V0H0v1.79l7 3.46zm0 2.03L0 3.8V11h14V3.82L7 7.28z" fill="#FFF" fillRule="nonzero"/></svg>
        </span>
        info@abbacruises.com
      </a>
    </div>
  );
}

export default ToolbarContact;
