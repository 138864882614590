import React, { Component } from 'react';
import Button from "../Button/Button";
import Shipwreck from '../../assets/shipwreck-tour.png';

class MainSliderSingleVideo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			url: 'https://uploads.plutio.com/s/J1D-n7BXdCiEThvfCbMEE8B4a2b0gqoAlInLCHKTOSSKJ-IpR3t9y-Bskrzj-shipwreck.mp4',
		}
	}


	render() {
		const { url } = this.state;

		return (

			<div className='main-slider__single'>
				<div className="main-slider__video-wrapper">

					<video playsInline autoPlay="autoplay" loop="loop" poster={Shipwreck} muted className='main-slider__player' >
						<source src={url} type="video/mp4" />
						Your browser does not support the video tag.
					</video>

				</div>
				<div className="main-slider__content">

					<h2 className="main-slider__text-heading
                        u-margin-bottom-small"
					>
          <span className="main-slider__text-subheading">
            {this.props.subHeading}
          </span>
						<span className="main-slider__text-main-heading">
            {this.props.mainHeading}
          </span>
					</h2>
					<p className="main-slider__text-paragraph
                      u-margin-bottom-medium"
					>
						{this.props.paragraph}
					</p>
					<Button
						classes="button--primary"
						link={this.props.linkTo}
					>
						{this.props.linkLabel}
					</Button>
				</div>
			</div>

		)
	}
}

export default MainSliderSingleVideo;
