import React from 'react';
import { connect } from "react-redux";

import Sidebar from '../../components/SideBar/SideBar';
import FeaturesList from '../../components/FeaturesList/FeaturesList';
import CategoryHeader from '../../components/CategoryHeader/CategoryHeader';
import Hoc from '../../hoc/hoc';

function ContactPage({ currentContent }) {
  return (
    <Hoc>
      <CategoryHeader
          type="category-header--contact-page"
          title="Contact"
      />
      <section className="contact-page">
        <div className="container
                        container--flex
                        "
        >
          <div className="contact-page__content">
            <h2 className="contact-page__heading
                            u-margin-bottom-medium"
            >
              <span className="contact-page__icon">
                <svg width="28" height="25" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor" fillRule="evenodd"><path d="M22.273 1.875c2.108 0 3.818 1.679 3.818 3.75v9.998c0 2.071-1.71 3.75-3.818 3.75H10.659l-4.295 3.75v-3.75h-.637c-2.108 0-3.818-1.679-3.818-3.75V5.624c0-2.07 1.71-3.75 3.818-3.75h16.546zm0-1.875H5.727C2.564 0 0 2.518 0 5.624v10c-.001 2.625 1.847 4.903 4.455 5.486v2.013a1.873 1.873 0 0 0 1.128 1.713c.688.303 1.492.18 2.053-.313l3.755-3.275h10.882c3.163 0 5.727-2.518 5.727-5.625V5.624C28 2.518 25.436 0 22.273 0z"/><circle cx="15" cy="11" r="1"/><circle cx="21" cy="11" r="1"/><circle cx="8" cy="11" r="1"/></g></svg>
              </span>
              { currentContent.contactPage.heading }
            </h2>
            <address className="contact-page__contact-address">
              <span className="contact-page__text-label">
                { currentContent.contactPage.address }:
              </span>
              Planos Zakynthos, Greece
            </address>
            <a href="tel:+30 26950 28607" className="contact-page__contact-link">
              <span className="contact-page__text-label">
                { currentContent.contactPage.mainOffice }:
              </span>
              +30 26950 28607
            </a>
            <a href="tel:+30 6976000125" className="contact-page__contact-link">
              <span className="contact-page__text-label">
                { currentContent.contactPage.boatRentals } (Alex):
              </span>
              +30 6976000125
            </a>
            <a href="tel:+30 6976000124" className="contact-page__contact-link">
              <span className="contact-page__text-label">
                { currentContent.contactPage.cruises } (Pavlos):
              </span>
              +30 6976000124
            </a>
            <a href="tel:+30 69777 41500" className="contact-page__contact-link">
              <span className="contact-page__text-label">
                { currentContent.contactPage.tourSpecialist } (Andreas):
              </span>
              +30 69777 41500
            </a>
            <a href="tel:+48 514723103" className="contact-page__contact-link">
              <span className="contact-page__text-label">
                { currentContent.contactPage.polishRepresentative } (Daria):
              </span>
              +48 514723103
            </a>
            <a href="mailto:info@abbacruises.com" className="contact-page__contact-link">
              info@abbacruises.com
            </a>
          </div>
          <Sidebar>
            <FeaturesList />
          </Sidebar>
        </div>
      </section>
    </Hoc>
  )
}

const mapStateToProps = state => {
  return {
    currentContent: state.currentContent,
  }
}

export default connect(mapStateToProps)(ContactPage);
