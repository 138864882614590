import React, { Component } from 'react';
import NavItem from '../NavItem/NavItem';
import { connect } from "react-redux";
import ToolbarLanguages from "../ToolbarLanguages/ToolbarLanguages";

class NavMobile extends Component {
  state = {
    isOpen: false
  }

  mobileNavHandler = () => {
   let isOpen = this.state.isOpen;
   this.setState({
     isOpen: !isOpen
   });
  }

  render() {
    let { isOpen } = this.state;
    const { nav } = this.props.currentContent;

    return (
      <div className="mobile-menu">
        <div className=
          {'mobile-menu__overlay ' + (isOpen ? 'mobile-menu__overlay--show' : '')}
            onClick={this.mobileNavHandler}
        />
        <button
          className="mobile-menu__button"
          onClick={this.mobileNavHandler}
        >
          <span className="mobile-menu__button-icon"/>
        </button>
        <nav
          className=
          {'mobile-menu__nav ' + (isOpen ? 'mobile-menu__nav--open' : '')}
        >
          <button
            className="mobile-menu__close-btn"
            onClick={this.mobileNavHandler}
          >
            <span className="mobile-menu__close-btn-icon"/>
          </button>
          {nav &&
          <ul className="mobile-menu__list">
            <NavItem
              category="tours"
              class="nav__link-mobile"
              classActive="nav__link-mobile--active"
              action={this.mobileNavHandler}
            >
              {nav.item1}
            </NavItem>
            <NavItem
              category="auto-moto"
              class="nav__link-mobile"
              classActive="nav__link-mobile--active"
              action={this.mobileNavHandler}
            >
              {nav.item2}
            </NavItem>
            <NavItem
              category="motor-boat"
              class="nav__link-mobile"
              classActive="nav__link-mobile--active"
              action={this.mobileNavHandler}
            >
              {nav.item3}
            </NavItem>
            <NavItem
              category="cruises"
              class="nav__link-mobile"
              classActive="nav__link-mobile--active"
              action={this.mobileNavHandler}
            >
              {nav.item4}
            </NavItem>
            <NavItem
              category="transport"
              class="nav__link-mobile"
              classActive="nav__link-mobile--active"
              action={this.mobileNavHandler}
            >
              {nav.item5}
            </NavItem>
            <NavItem
              category="accommodation"
              class="nav__link-mobile"
              classActive="nav__link-mobile--active"
              action={this.mobileNavHandler}
            >
              {nav.item6}
            </NavItem>
            <NavItem
              category="contact"
              class="nav__link-mobile"
              classActive="nav__link-mobile--active"
              action={this.mobileNavHandler}
            >
              {nav.item7}
            </NavItem>
          </ul>
          }
        <ToolbarLanguages/>
        </nav>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentContent: state.currentContent,
  }
}

export default connect(mapStateToProps)(NavMobile);
