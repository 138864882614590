import React from 'react';
import { Link } from 'react-router-dom';

function FooterServices({ content }) {

  return(
    <div className="footer__services
                    footer__single"
    >
      <h3 className="footer__text-heading
                      u-margin-bottom-small"
      >
        { content.topServices }
      </h3>
      <ul className="footer__services-list">
        <li className="footer__services-single">
          <Link
            to="/tours/1"
            className="footer__services-link
            footer__services-link--shipwreck-tour"
          >
            <h4 className="footer__text-services-heading">
              Shipwreck
            </h4>
          </Link>
        </li>
        <li className="footer__services-single">
          <Link
            to="/tours/7"
            className="footer__services-link
            footer__services-link--fishing-tour"
          >
            <h4 className="footer__text-services-heading">
              Fishing
            </h4>
          </Link>
        </li>
        <li className="footer__services-single">
          <Link
            to="/tours/13"
            className="footer__services-link
            footer__services-link--sunset-tour"
          >
            <h4 className="footer__text-services-heading">
              Sunset
            </h4>
          </Link>
        </li>
        <li className="footer__services-single">
          <Link
            to="/tours/9"
            className="footer__services-link
            footer__services-link--round-trip"
          >
            <h4 className="footer__text-services-heading">
              Real Zakynthos
            </h4>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default FooterServices;
