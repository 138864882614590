import React from "react";

function ToolbarSocial(props) {
  return (
    <ul className="toolbar__social-list">
      <li className="toolbar__social-single">
        <a
          href="https://www.facebook.com/boatrips/"
          className={
            "toolbar__social-link " +
            (props.socialClass ? props.socialClass : "")
          }
          target="_blank"
          rel="noreferrer"
        >
          <span className="toolbar__icon">
            <svg width="8" height="15" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.5 2.81H8V0H5.5C3.57 0 2 1.47 2 3.28v1.4H0V7.5h2V15h3V7.5h2.5L8 4.69H5v-1.4c0-.26.23-.48.5-.48z"
                fill="currentColor"
                fillRule="nonzero"
              />
            </svg>
          </span>
        </a>
      </li>
      <li className="toolbar__social-single">
        <a
          href="https://www.instagram.com/abbatravelzakynthos/"
          className={
            "toolbar__social-link " +
            (props.socialClass ? props.socialClass : "")
          }
          target="_blank"
          rel="noreferrer"
        >
          <span className="toolbar__icon">
            <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.33 0H1.67C.75 0 0 .75 0 1.67v11.66C0 14.25.75 15 1.67 15h11.66c.92 0 1.67-.75 1.67-1.67V1.67C15 .75 14.25 0 13.33 0zM7.5 12.1a4.62 4.62 0 0 0 4.53-5.43h1.31v6.01c0 .32-.26.58-.57.58H2.24a.58.58 0 0 1-.57-.58V6.67h1.29a4.62 4.62 0 0 0 4.53 5.44zM4.6 7.5a2.88 2.88 0 1 1 5.77 0 2.88 2.88 0 0 1-5.77 0zm8.16-3.32H11.4a.58.58 0 0 1-.58-.58V2.24c0-.32.26-.57.58-.57h1.35c.31 0 .57.25.57.57V3.6c0 .32-.26.58-.57.58z"
                fill="currentColor"
                fillRule="nonzero"
              />
            </svg>
          </span>
        </a>
      </li>
      {/* <li className="toolbar__social-single">
        <a href="/" 
            className="toolbar__social-link"
        >
          <span className="toolbar__icon">
            <svg width="19" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M18.8 3.02s-.18-1.37-.75-1.97c-.72-.8-1.53-.8-1.9-.85C13.49 0 9.5 0 9.5 0s-4 0-6.65.2c-.37.05-1.18.05-1.9.85-.58.6-.76 1.97-.76 1.97S0 4.63 0 6.24v1.51c0 1.61.19 3.22.19 3.22s.19 1.38.75 1.98c.73.8 1.68.77 2.1.85 1.52.15 6.46.2 6.46.2s4 0 6.65-.2c.37-.05 1.18-.06 1.9-.85.57-.6.76-1.97.76-1.97S19 9.37 19 7.75v-1.5c0-1.62-.2-3.23-.2-3.23zM7.54 9.58v-5.6l5.14 2.82-5.14 2.78z" fill={props.color} fillRule="nonzero"/></svg>
          </span>
        </a>
      </li> */}
    </ul>
  );
}

export default ToolbarSocial;
