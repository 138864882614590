import React from "react";

function SingleOffer(props) {
  const pathToAssets = require.context(
    "../../assets/",
    true,
    /\.(png|jpe?g|svg)$/
  );
  let galleryItems, includes, excludes, toExpect, flayers;
  const details = props.details.map((detail, index) => (
    <p key={index} className="single-offer__paragraph u-margin-bottom-small">
      {detail}
    </p>
  ));

  if (props.includes) {
    includes = props.includes.map((include, index) => (
      <li key={index} className="single-offer__item-single-feature">
        <span className="single-offer__icon">
          <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.604 7.188L3.4 8.44l3.869 4.027 8.598-8.948-1.204-1.252L7.269 9.96 4.604 7.188zM15.3 8.5c0 3.74-3.06 6.8-6.8 6.8-3.74 0-6.8-3.06-6.8-6.8 0-3.74 3.06-6.8 6.8-6.8.68 0 1.275.085 1.87.255l1.36-1.36C10.71.255 9.605 0 8.5 0 3.825 0 0 3.825 0 8.5S3.825 17 8.5 17 17 13.175 17 8.5h-1.7z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </span>
        <p className="single-offer__paragraph">{include}</p>
      </li>
    ));
  }

  if (props.excludes) {
    excludes = props.excludes.map((exclude, index) => (
      <li key={index} className="single-offer__item-single-feature">
        <span className="single-offer__icon">
          <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.5 0C3.825 0 0 3.825 0 8.5S3.825 17 8.5 17 17 13.175 17 8.5 13.175 0 8.5 0zM1.7 8.5c0-3.74 3.06-6.8 6.8-6.8 1.53 0 2.975.51 4.165 1.445l-9.52 9.52A6.699 6.699 0 0 1 1.7 8.5zm6.8 6.8c-1.53 0-2.975-.51-4.165-1.445l9.52-9.52A6.699 6.699 0 0 1 15.3 8.5c0 3.74-3.06 6.8-6.8 6.8z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </span>
        <p className="single-offer__paragraph">{exclude}</p>
      </li>
    ));
  }

  if (props.toExpect) {
    toExpect = props.toExpect.map((single, index) => (
      <p key={index} className="single-offer__paragraph u-margin-bottom-small">
        {single}
      </p>
    ));
  }

  if (props.imgs) {
    galleryItems = props.imgs.map((img, index) => (
      <li
        key={index}
        className={`single-offer__gallery-item 
      single-offer__gallery-item--`}
      >
        <img
          src={`${pathToAssets(img).default}`}
          alt="Gallery"
          className="single-offer__gallery-img"
        />
      </li>
    ));
  }
  if (props.flayers) {
    flayers = props.flayers.map((img, index) => (
      <li key={index} className={`single-offer__gallery-item--full`}>
        <img
          src={`${pathToAssets(img).default}`}
          alt="Gallery"
          className="single-offer__gallery-img"
        />
      </li>
    ));
  }

  return (
    <section className="single-offer">
      <article className="single-offer__details">
        <header className="single-offer__article-header u-margin-bottom-medium">
          <span className="single-offer__icon">
            <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.667 0H2.333A2.34 2.34 0 0 0 0 2.333v16.334A2.34 2.34 0 0 0 2.333 21h16.334A2.34 2.34 0 0 0 21 18.667V2.333A2.34 2.34 0 0 0 18.667 0zM7 16.333H4.667V14H7v2.333zm0-4.666H4.667V9.333H7v2.334zM7 7H4.667V4.667H7V7zm9.333 9.333H8.167V14h8.166v2.333zm0-4.666H8.167V9.333h8.166v2.334zm0-4.667H8.167V4.667h8.166V7z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </span>
          <h3 className="single-offer__heading">
            { props.content.heading1 }
          </h3>
        </header>
        {details}

        <ul className="single-offer__list u-margin-top-big">
          {props.hours ? (
            <li className="single-offer__item">
              <span className="single-offer__item-label">{ props.content.time }</span>
              <div
                className="single-offer__item-single-feature
                                single-offer__item-single-feature--only"
              >
                <p className="single-offer__paragraph">{props.hours}</p>
              </div>
            </li>
          ) : null}

          {props.departure && props.return ? (
            <li className="single-offer__item">
              <span className="single-offer__item-label">
                { props.content.location }
              </span>
              <ul className="single-offer__item-features">
                <li className="single-offer__item-single-feature">
                  <span className="single-offer__icon">
                    <svg
                      width="17"
                      height="17"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.604 7.188L3.4 8.44l3.869 4.027 8.598-8.948-1.204-1.252L7.269 9.96 4.604 7.188zM15.3 8.5c0 3.74-3.06 6.8-6.8 6.8-3.74 0-6.8-3.06-6.8-6.8 0-3.74 3.06-6.8 6.8-6.8.68 0 1.275.085 1.87.255l1.36-1.36C10.71.255 9.605 0 8.5 0 3.825 0 0 3.825 0 8.5S3.825 17 8.5 17 17 13.175 17 8.5h-1.7z"
                        fill="currentColor"
                        fillRule="evenodd"
                      />
                    </svg>
                  </span>
                  <p className="single-offer__paragraph">{props.departure}</p>
                </li>
                <li className="single-offer__item-single-feature">
                  <span className="single-offer__icon">
                    <svg
                      width="17"
                      height="17"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.604 7.188L3.4 8.44l3.869 4.027 8.598-8.948-1.204-1.252L7.269 9.96 4.604 7.188zM15.3 8.5c0 3.74-3.06 6.8-6.8 6.8-3.74 0-6.8-3.06-6.8-6.8 0-3.74 3.06-6.8 6.8-6.8.68 0 1.275.085 1.87.255l1.36-1.36C10.71.255 9.605 0 8.5 0 3.825 0 0 3.825 0 8.5S3.825 17 8.5 17 17 13.175 17 8.5h-1.7z"
                        fill="currentColor"
                        fillRule="evenodd"
                      />
                    </svg>
                  </span>
                  <p className="single-offer__paragraph">{props.return}</p>
                </li>
              </ul>
            </li>
          ) : null}

          {includes ? (
            <li className="single-offer__item">
              <span className="single-offer__item-label">{ props.content.includes }</span>
              <ul className="single-offer__item-features">{includes}</ul>
            </li>
          ) : null}

          {excludes ? (
            <li className="single-offer__item">
              <span className="single-offer__item-label">{ props.content.excludes }</span>
              <ul className="single-offer__item-features">{excludes}</ul>
            </li>
          ) : null}
        </ul>
      </article>

      {props.imgs ? (
        <article className="single-offer__description">
          <header className="single-offer__article-header u-margin-bottom-medium">
            <span className="single-offer__icon">
              <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22 16V2c0-1.1-.9-2-2-2H6C4.9 0 4 .9 4 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM9.5 10.5l2.5 3L15.5 9l4.5 6H6l3.5-4.5zM0 4v16c0 1.1.9 2 2 2h16v-2H2V4H0z"
                  fill="currentColor"
                  fillRule="evenodd"
                />
              </svg>
            </span>
            <h3 className="single-offer__heading">{ props.content.heading2 }</h3>
          </header>
          <ul className="single-offer__gallery">{galleryItems}</ul>
        </article>
      ) : null}

      {props.flayers ? (
        <article className="single-offer__description">
          <header className="single-offer__article-header u-margin-bottom-medium">
            <span className="single-offer__icon">
              <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22 16V2c0-1.1-.9-2-2-2H6C4.9 0 4 .9 4 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM9.5 10.5l2.5 3L15.5 9l4.5 6H6l3.5-4.5zM0 4v16c0 1.1.9 2 2 2h16v-2H2V4H0z"
                  fill="currentColor"
                  fillRule="evenodd"
                />
              </svg>
            </span>
            <h3 className="single-offer__heading">{ props.content.seeMore }</h3>
          </header>
          <ul className="single-offer__gallery">{flayers}</ul>
        </article>
      ) : null}

      {props.toExpect ? (
        <article className="single-offer__description">
          <header className="single-offer__article-header u-margin-bottom-medium">
            <span className="single-offer__icon">
              <svg width="32" height="17" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.91 0l-5.528 7.083 4.218 5.384-2.327 1.7c-2.473-3.117-6.546-8.5-6.546-8.5L0 17h32L18.91 0z"
                  fill="currentColor"
                  fillRule="evenodd"
                />
              </svg>
            </span>
            <h3 className="single-offer__heading">{ props.content.heading3 }</h3>
          </header>
          {toExpect}

          {/* <ul className="single-offer__description-list u-margin-top-big">
              <li className="single-offer__description-item">
                <span className="single-offer__icon">
                  <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 3.25c-1.82 0-3.25 1.43-3.25 3.25S4.68 9.75 6.5 9.75 9.75 8.32 9.75 6.5 8.32 3.25 6.5 3.25zM6.5 0C2.925 0 0 2.925 0 6.5S2.925 13 6.5 13 13 10.075 13 6.5 10.075 0 6.5 0zm0 11.7c-2.86 0-5.2-2.34-5.2-5.2 0-2.86 2.34-5.2 5.2-5.2 2.86 0 5.2 2.34 5.2 5.2 0 2.86-2.34 5.2-5.2 5.2z" fill="currentColor" fillRule="evenodd"/></svg>
                </span>
                <p className="single-offer__paragraph">
                  Lorem ipsum dolor sit.
                </p>
              </li>
              <li className="single-offer__description-item">
                <span className="single-offer__icon">
                  <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 3.25c-1.82 0-3.25 1.43-3.25 3.25S4.68 9.75 6.5 9.75 9.75 8.32 9.75 6.5 8.32 3.25 6.5 3.25zM6.5 0C2.925 0 0 2.925 0 6.5S2.925 13 6.5 13 13 10.075 13 6.5 10.075 0 6.5 0zm0 11.7c-2.86 0-5.2-2.34-5.2-5.2 0-2.86 2.34-5.2 5.2-5.2 2.86 0 5.2 2.34 5.2 5.2 0 2.86-2.34 5.2-5.2 5.2z" fill="currentColor" fillRule="evenodd"/></svg>
                </span>
                <p className="single-offer__paragraph">
                  Lorem ipsum dolor sit.
                </p>
              </li>
              <li className="single-offer__description-item">
                <span className="single-offer__icon">
                  <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 3.25c-1.82 0-3.25 1.43-3.25 3.25S4.68 9.75 6.5 9.75 9.75 8.32 9.75 6.5 8.32 3.25 6.5 3.25zM6.5 0C2.925 0 0 2.925 0 6.5S2.925 13 6.5 13 13 10.075 13 6.5 10.075 0 6.5 0zm0 11.7c-2.86 0-5.2-2.34-5.2-5.2 0-2.86 2.34-5.2 5.2-5.2 2.86 0 5.2 2.34 5.2 5.2 0 2.86-2.34 5.2-5.2 5.2z" fill="currentColor" fillRule="evenodd"/></svg>
                </span>
                <p className="single-offer__paragraph">
                  Lorem ipsum dolor sit.
                </p>
              </li>
              <li className="single-offer__description-item">
                <span className="single-offer__icon">
                  <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 3.25c-1.82 0-3.25 1.43-3.25 3.25S4.68 9.75 6.5 9.75 9.75 8.32 9.75 6.5 8.32 3.25 6.5 3.25zM6.5 0C2.925 0 0 2.925 0 6.5S2.925 13 6.5 13 13 10.075 13 6.5 10.075 0 6.5 0zm0 11.7c-2.86 0-5.2-2.34-5.2-5.2 0-2.86 2.34-5.2 5.2-5.2 2.86 0 5.2 2.34 5.2 5.2 0 2.86-2.34 5.2-5.2 5.2z" fill="currentColor" fillRule="evenodd"/></svg>
                </span>
                <p className="single-offer__paragraph">
                  Lorem ipsum dolor sit.
                </p>
              </li>
            </ul> */}
        </article>
      ) : null}
    </section>
  );
}

export default SingleOffer;
