import React from 'react';
import ListIconItem from '../FeatureItem/ListIconItem';

function BookWithUs({ content }) {
  return (
    <section className="book-with-us">
      <div className="container book-with-us__wrapper">
        <div className="book-with-us__title">
          <h2 className="book-with-us__heading">
            { content.heading }
          </h2>
        </div>
        <ul className="book-with-us__details-list">
          <ListIconItem
            itemClass="book-with-us__details-item"
            title={content.items.item1.title}
            content={content.items.item1.content}
          >
            <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M36 0H4C1.8 0 0 1.8 0 4v36l8-8h28c2.2 0 4-1.8 4-4V4c0-2.2-1.8-4-4-4zM8 14h24v4H8v-4zm16 10H8v-4h16v4zm8-12H8V8h24v4z" fill="currentColor" fillRule="evenodd"/></svg>
          </ListIconItem>
          <ListIconItem
            itemClass="book-with-us__details-item"
            title={content.items.item2.title}
            content={content.items.item2.content}
          >
            <svg width="40" height="26" xmlns="http://www.w3.org/2000/svg"><path d="M27.273 10.91c3.09 0 5.454-2.365 5.454-5.455C32.727 2.364 30.364 0 27.273 0c-3.091 0-5.455 2.364-5.455 5.455 0 3.09 2.364 5.454 5.455 5.454zm-14.546 0c3.091 0 5.455-2.365 5.455-5.455C18.182 2.364 15.818 0 12.727 0c-3.09 0-5.454 2.364-5.454 5.455 0 3.09 2.363 5.454 5.454 5.454zm0 3.635C8.545 14.545 0 16.727 0 20.91v4.546h25.455v-4.546c0-4.182-8.546-6.364-12.728-6.364zm14.546 0c-.546 0-1.091 0-1.818.182 2.181 1.455 3.636 3.637 3.636 6.182v4.546H40v-4.546c0-4.182-8.545-6.364-12.727-6.364z" fill="currentColor" fillRule="evenodd"/></svg>
          </ListIconItem>
          <ListIconItem
            itemClass="book-with-us__details-item"
            title={content.items.item3.title}
            content={content.items.item3.content}
          >
            <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M20 0C9 0 0 9 0 20s9 20 20 20 20-9 20-20S31 0 20 0zm-2 35.8C10.2 34.8 4 28 4 20c0-1.2.2-2.4.4-3.6L14 26v2c0 2.2 1.8 4 4 4v3.8zm13.8-5c-.6-1.6-2-2.8-3.8-2.8h-2v-6c0-1.2-.8-2-2-2H12v-4h4c1.2 0 2-.8 2-2v-4h4c2.2 0 4-1.8 4-4v-.8c5.8 2.4 10 8.2 10 14.8 0 4.2-1.6 8-4.2 10.8z" fill="currentColor" fillRule="evenodd"/></svg>
          </ListIconItem>
        </ul>
      </div>
    </section>
  )
}

export default BookWithUs;
